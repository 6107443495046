@import '../../../theme/styles/variables/index';

.time-tags {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.time-tag {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 52px;
  height: 32px;
  margin-bottom: 8px;
  color: $text-black;
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  background: $color-primary;
  border-radius: 4px;
  cursor: pointer;

  &:not(:last-child) {
    margin-right: 8px;
  }

  &--more {
    color: $text-black;
    background-color: #fff;
    border: 1px solid $color-primary;
  }
}
