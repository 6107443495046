@import '../../../theme/styles/variables/index';

$image-size: 160px;
$small-image-size: 72px;

.place-card {
  position: relative;
  width: calc(50% - 8px);
  margin-bottom: 16px;
  padding: 12px;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: $box-shadow-light;

  &__container {
    display: flex;
    max-width: calc(100vw - 24px);
  }

  &__image-wrapper {
    position: relative;
    width: $image-size;
    height: $image-size;
  }

  &__image {
    border-radius: 4px;
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 5px;
    width: calc(100% - #{$image-size});
    min-width: 0;
    margin-left: 16px;
  }

  &__row {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    line-height: 26px;
  }

  &__name-row {
    @include text-overflow-ellipsis; // stylelint-disable-line

    width: calc(100% - 24px);
  }

  &__like {
    position: absolute;
    top: 14px;
    right: 14px;
    cursor: pointer;

    &:hover .fill {
      fill: $text-black;
    }
  }

  &__map-icon {
    flex-shrink: 0;
  }

  &__map-link {
    @include text-overflow-ellipsis; // stylelint-disable-line

    display: inline-block;
    padding: 0 8px;
    white-space: nowrap;
  }

  &__name {
    @include text-overflow-ellipsis; // stylelint-disable-line

    display: inline;
    color: $text-black;
    font-weight: 700;
    font-size: 18px;
  }

  &__price {
    margin-left: 12px;
  }

  &__controls {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  &__status {
    display: flex;
    justify-content: flex-end;
    margin-right: 16px;
    color: $text-default;
    font-weight: 500;
    font-size: 14px;
    line-height: 32px;
  }

  &__status-icon {
    display: flex;
    align-items: center;
    margin-right: 8px;
  }

  &__cancel-reservation {
    display: flex;
    justify-content: flex-end;
  }

  &--inline {
    width: 100%;
    margin-bottom: 0;
    border-bottom: 1px solid #d9d9d9;
    box-shadow: none;
  }

  &--small {
    .place-card {
      &__controls {
        display: block;
      }

      &__image-wrapper {
        width: $small-image-size;
        height: $small-image-size;
      }

      &__content {
        width: calc(100% - #{$small-image-size});
      }

      &__row {
        min-width: 0;

        &--growing {
          width: calc(100% + 82px);
          margin-left: -82px;
        }
      }
    }
  }

  &__promo-label {
    position: absolute;
    top: -3px;
    right: 4px;
  }

  &__reservations-today {
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 12px;
  }
}

@media screen and (max-width: 1060px) {
  .place-card {
    width: 100%;
  }
}
