@import '../../theme/styles/variables/index';

$notifications-summary-height: 44px;

.notifications {
  &__summary {
    height: $notifications-summary-height;
    color: $text-secondary;
    line-height: $notifications-summary-height;
    text-align: center;
    box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
  }

  &__container {
    padding: 0 24px;
    background-color: $color-primary-light;
  }

  &__content {
    overflow: auto;
  }

  &__title {
    padding-top: 12px;
  }

  &__list {
    margin-top: 18px;
  }

  .notification-item {
    display: flex;
    align-items: center;
    margin-bottom: 12px;

    &__container {
      display: flex;
      align-items: flex-start;
      padding: 16px;
      background-color: #fff;
      border-radius: 4px;
      box-shadow: 1px 4px 12px rgba(0, 0, 0, 0.15);
    }

    &__icon {
      display: flex;
      align-items: center;
      height: 22px;
    }

    &__text {
      margin-left: 10px;
      line-height: 22px;
    }

    &__date {
      margin-left: 12px;
    }
  }
}
