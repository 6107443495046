@import '../../../theme/styles/variables/index';

.promo-label {
  z-index: 1;
  pointer-events: none;

  &__badge {
    width: 48px;
  }

  &__badge-content {
    position: absolute;
    top: 8px;
    right: 8px;
    left: 8px;
    font-weight: 500;
    font-size: 13px;
    text-align: center;
  }

  &__percent {
    margin-bottom: 2px;
  }

  .svg-icon__PRITULA {
    //width: ;
  }

  .svg-icon__ZHITTELUB {
    width: 30px;
  }
}

@media screen and (max-width: $mobile-view-width) {
  .promo-label {
    &--scalable {
      transform: scale(0.8) translate(6px, -6px);
    }
  }
}
