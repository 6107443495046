@import '../../theme/styles/variables/index';

.gpt-search {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px 48px;
  background-image: url('../../theme/images/gpt_background.jpg');
  background-position: center;
  background-size: cover;

  &__back {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 16px;
    font-size: 18px;
    color: #fff;
  }

  &__container {
    width: 100%;
    max-width: 980px;
  }

  &__card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 24px;
    min-height: 70vh;
    padding: 24px;
    border-radius: 24px;
    border: 2px solid white;
    backdrop-filter: blur(50px);
    background-image: linear-gradient(125deg, rgba(255, 255, 255, 0.5) 2%, rgba(255, 255, 255, 0.21) 97%);
  }

  &__title {
    color: #fff;
    font-size: 24px;
    font-weight: 700;
    text-align: center;
  }

  &__subtitle {
    color: #fff;
    font-size: 24px;
  }

  &__search-row {
    position: relative;
    margin-top: 32px;
  }

  &__input-wrapper {
    width: 100%;

    .svg-icon {
      top: 24px;
      width: 20px;
      right: 80px;
    }
  }

  &__input {
    width: 100%;
    height: 70px;
    padding-left: 24px;
    padding-right: 108px;
    border-radius: 16px;
  }

  &__send {
    position: absolute;
    top: 3px;
    right: 3px;
    min-width: unset !important;
    width: 64px;
    height: 64px;
    border-radius: 12px;

    .svg-icon {
      width: 24px;
    }
  }

  &__messages {
    display: flex;
    flex-direction: column;
    gap: 10px;
    max-height: 50vh;
    overflow-y: auto;
    padding: 15px;
  }

  &__message-wrapper {
    display: flex;
    align-items: center;
    gap: 24px;

    .svg-icon {
      flex-shrink: 0;
      width: 40px;
    }

    &--user {
      align-self: flex-end;
      padding-left: 128px;
    }

    &--assistant {
      padding-right: 128px;
    }
  }

  &__message {
    display: inline-flex;
    flex-direction: column;
    gap: 8px;
    padding: 20px;
    border-radius: 8px;
    max-width: 50vw;
    text-align: left;
    font-size: 16px;
    line-height: 18px;
    background-color: white;

    ol,
    ul {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }
  }

  &__place-cards {
    max-width: 100%;
    display: flex;
    gap: 16px;
    margin-top: 16px;
    overflow-x: auto;
  }

  .place-card {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 180px;
    padding: 8px;
    background-color: white;
    border-radius: 8px;
    cursor: pointer;

    &__image {
      width: 100%;
      height: 150px;
      object-fit: cover;
      border-radius: 4px;
    }

    &__name-row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 8px;
      width: 100%;
    }

    &__name {
      @include text-overflow-ellipsis; // stylelint-disable-line

      font-size: 16px;
      font-weight: 700;
    }

    &__rating {
      display: flex;
      gap: 4px;
      font-size: 12px;
      font-weight: 700;
    }

    &__address-row {
      display: flex;
      align-items: center;
      gap: 4px;
      width: 100%;

      .svg-icon {
        width: 16px;
      }
    }

    &__address {
      @include text-overflow-ellipsis; // stylelint-disable-line

      font-size: 14px;
    }
  }
}

@media screen and (max-width: 900px) {
  .gpt-search {
    &__message {
      max-width: 65vw;
      font-size: 14px;
    }
  }
}

@media screen and (max-width: $mobile-view-width) {
  .gpt-search {
    padding: 12px;

    &__messages {
      margin-top: 24px;
    }

    &__message-wrapper {
      &--user {
        padding-left: 0;
      }

      &--assistant {
        padding-right: 0;
      }
    }

    &__message {
      padding: 12px 20px;
    }
  }
}
