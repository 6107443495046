@import '../../../theme/styles/variables/index';

.confirm-legal {
  color: $text-default;
  font-size: 12px;
  line-height: 20px;

  &__link {
    display: inline;
  }
}
