@import '../../../theme/styles/variables/index';

.certificate-card {
  padding: 16px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.15);

  &__top {
    display: flex;
    gap: 16px;
  }

  &__bottom {
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
  }

  &__image-wrapper {
    width: 144px;
    height: 100px;
    border-radius: 8px;
    flex-shrink: 0;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 8px;
  }

  /* stylelint-disable */
  &__name {
    @include text-overflow-ellipsis;
    font-size: 18px;
    font-weight: 600;
  }
  /* stylelint-enable */

  &__category {
    display: inline-flex;
    margin: 8px 0;
    padding: 4px 8px;
    font-size: 14px;
    border: 1px solid $text-black;
    border-radius: 8px;
  }

  &__description {
    font-size: 12px;
    line-height: 16px;
    color: $text-default;
  }

  &__price {
    font-size: 18px;
    font-weight: bold;
    color: $color-primary;
  }
}

@media screen and (max-width: $mobile-view-width) {
  .certificate-card {
    &__top {
      flex-direction: column;
    }

    &__image-wrapper {
      width: 100%;
      height: 150px;
    }
  }
}
