@import '../../../theme/styles/variables/index';

.detailed-rating {
  padding: 16px;
  text-align: center;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);

  &__heading {
    color: $text-secondary;
    font-weight: 500;
    font-size: 16px;
  }

  &__rating {
    margin: 4px 0 10px;
    color: $text-black;
    font-size: 36px;
    line-height: 44px;
  }

  &__stars {
    width: max-content;
    margin: 0 auto;
  }

  &__divider {
    height: 1px;
    margin: 16px 0;
    background-color: $text-grey;
  }

  &__stat {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: $text-default;
    font-weight: 500;
    font-size: 14px;

    &:not(:last-child) {
      margin-bottom: 12px;
    }

    &--name {
      width: 47%;
      text-align: right;
    }

    &--value {
      width: 47%;
    }
  }

  &__button {
    margin: 10px auto 0;
  }

  &__button-icon {
    margin-right: 10px;
  }

  &__empty {
    text-align: center;
  }

  &__text {
    color: $text-default;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
  }
}
