@import '../../../theme/styles/variables/index';

.select-spot-modal {
  padding: 0 !important;
  width: 90vw;
  height: 90vh;
  display: flex;
  flex-direction: column;

  .modal__title {
    margin-bottom: 0;
    padding: 24px;
    flex-shrink: 0;
  }

  .modal__content {
    position: relative;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }

  .stage-wrapper {
    flex-grow: 1;
    position: relative;
    padding: 16px 16px 52px;
    background-color: #E9E9E9;
    overflow: auto;
  }

  .stage {
    overflow: auto;
    width: 100%;
    min-height: 100%;
    display: flex;
    align-items: center;
  }

  .konvajs-content {
    margin: 0 auto;
  }

  .zoom-controls {
    position: absolute;
    bottom: 88px;
    left: 16px;
    display: flex;
    gap: 8px;
    padding: 8px;
    background-color: white;
    border-radius: 8px;

    &__button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 24px;
      height: 24px;
      font-size: 32px;
      border: none;
      border-radius: 4px;
      box-shadow: 0 0 4px rgba(0, 0, 0, 0.8);
      cursor: pointer;
    }
  }

  .footer {
    padding: 16px;
    background-color: white;

    &__submit .button {
      width: 100%;
      height: 42px;
      border-radius: 8px;
      padding: 0 40px;
    }
  }

  .select-hall {
    margin-bottom: 16px;
    margin-left: 40px;
    margin-right: 40px;
    flex-shrink: 0;

    .slick-arrow {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 34px;
      height: 34px;
      border-radius: 50%;
      color: white;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.12);

      &::before {
        font-family: unset;
        font-size: 18px;
        font-weight: bold;
        color: $text-default;
      }
    }

    .slick-next {
      right: -40px;

      &::before {
        content: '→';
      }
    }

    .slick-prev {
      left: -40px;

      &::after {
        content: '←';
      }
    }

    &__button {
      height: 34px;
      margin-right: 10px;
      display: inline-flex;
      align-items: center;
      gap: 5px;
      min-width: unset;
      padding: 0 16px;
      border-radius: 50px;
      border: none;
      background-color: $color-primary;
      cursor: pointer;

      &.button--flat {
        background-color: white;
      }
    }
  }

  .deposit-type-popup {
    max-width: 260px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 8px;
    background-color: white;
    border-radius: 8px;
    font-size: 12px;

    &__row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 8px;
      cursor: pointer;
    }

    &__left {
      display: flex;
      align-items: center;
      gap: 8px;
    }

    &__type-name {
      color: $text-secondary;
    }

    &__price {
      white-space: nowrap;
      flex-shrink: 0;
    }
  }
}

@media screen and (min-width: $mobile-view-width) {
  .select-spot-modal {
    .footer {
      display: flex;
      justify-content: flex-end;
    }
  }
}
