@import '../../../theme/styles/variables/index';

.checkbox-container {
  position: relative;
  display: inline-flex;
  align-items: center;
  font-size: 14px;
  line-height: 20px;
  cursor: pointer;

  .area {
    position: relative;
    flex: 0 0 auto;
    width: 16px;
    height: 16px;
    border: 1px solid $text-grey;
    border-radius: 2px;
    -webkit-transition: all $transition;
    transition: all $transition;
  }

  .checkmark {
    position: absolute;
    top: 1px;
    left: 1px;
    display: none;
  }

  .text {
    margin-left: 5px;
    color: $text-default;
  }

  &:hover .area {
    border-color: $color-primary-strong;
  }

  .checkbox {
    position: absolute;
    opacity: 0;

    &:checked {
      & ~ .area {
        background-color: $color-primary-strong;
        border-color: $color-primary-strong;

        .checkmark {
          display: block;

          .fill {
            fill: #fff;
          }
        }
      }
    }

    &[disabled] {
      & ~ .area {
        background-color: $text-light-grey;
        border-color: $text-grey;

        .checkmark .fill {
          fill: $text-disabled;
        }
      }

      & ~ .text {
        color: $text-disabled;
      }
    }
  }

  &:hover {
    .checkbox[disabled] ~ .area {
      border-color: $text-grey;
    }
  }
}
