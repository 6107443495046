.persons-selector {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 16px;

  &__icon {
    width: 24px;
  }
}
