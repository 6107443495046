@import '../../../theme/styles/variables/index';

$sliding-menu-width: 270px;

.sliding-menu-overlay {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 8000;
  background: rgba(0, 0, 0, 0.7);
  opacity: 0;
  -webkit-transition: opacity $transition;
  transition: opacity $transition;
  pointer-events: none;

  &--visible {
    opacity: 1;
    pointer-events: auto;
  }

  .sliding-menu {
    position: absolute;
    top: 0;
    right: -$sliding-menu-width;
    bottom: 0;
    width: $sliding-menu-width;
    overflow-y: auto;
    background: #fff;
    outline: none;
    -webkit-transition: right $transition;
    transition: right $transition;
    pointer-events: none;

    &--visible {
      right: 0;
      pointer-events: auto;
    }

    &__content {
      position: relative;
      padding: $sliding-menu-padding;

      &--expanded {
        min-height: 710px;
      }
    }
  }
}
