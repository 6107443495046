@import '../../../theme/styles/variables/index';

.switch {
  display: flex;
  border-radius: 20px;
  cursor: pointer;
  border: 1px solid white;
  background: white;
  font-weight: bold;

  &__item {
    padding: 8px 16px;
    border-radius: 20px;
    font-size: 14px;
  }

  &__active {
    background-color: $color-primary;
    padding: 8px 16px;
    color: white;
  }
}
