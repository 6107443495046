@import '../../../theme/styles/variables/index';

.loader {
  &--absolute {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 9000;
    transform: translate(-50%, -50%);
  }

  &--inline {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
  }

  .lds-ripple {
    position: relative;
    display: inline-block;
    width: 80px;
    height: 80px;
  }

  .lds-ripple div {
    position: absolute;
    border: 4px solid #000;
    border-radius: 50%;
    opacity: 1;
    animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
  }

  .lds-ripple div:nth-child(2) {
    animation-delay: -0.5s;
  }

  @keyframes lds-ripple {
    0% {
      top: 36px;
      left: 36px;
      width: 0;
      height: 0;
      opacity: 1;
    }

    100% {
      top: 0;
      left: 0;
      width: 72px;
      height: 72px;
      opacity: 0;
    }
  }
}
