@import '../../../theme/styles/variables/index';

.feedback {
  position: relative;
  display: flex;

  &__current {
    height: 20px;
    margin-right: 15px;
    padding: 0 8px;
    color: #fff;
    font-size: 14px;
    line-height: 20px;
    background-color: $color-primary;
    border-radius: 4px;
    box-shadow: $box-shadow-light;
  }

  &__avatar {
    flex: 0 0 auto;
  }

  &__content {
    width: calc(100% - 56px);
    margin-left: 8px;
  }

  &__top-right {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
  }

  &__edit {
    margin-right: 12px;
    cursor: pointer;
  }

  &__date {
    color: $text-secondary;
    font-size: 14px;
  }

  &__flex-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
  }

  &__name {
    color: $text-black;
    font-weight: 500;
    font-size: 16px;
  }

  &__title {
    margin-right: 6px;
    margin-left: 8px;
    color: $text-secondary;
    font-weight: 500;
    font-size: 16px;
  }

  &__rating {
    margin-left: 18px;
  }

  &__text {
    color: $text-default;
    font-size: 16px;
    line-height: 22px;
  }

  &__expand {
    color: $text-default;
    font-size: 16px;
    font-weight: bold;
    line-height: 22px;
    cursor: pointer;
  }
}

@media screen and (max-width: $mobile-view-width) {
  .feedback {
    padding-bottom: 10px;

    &::after {
      @include grey-border; //stylelint-disable-line

      bottom: 0;
    }

    &__avatar {
      display: none;
    }

    &__content {
      width: 100%;
      margin-left: 0;
    }

    &__name {
      flex-basis: 100%;
      order: 3;
      margin-top: 8px;
    }

    &__rating {
      margin-left: 0;
    }

    &__edit {
      position: absolute;
      top: 30px;
      right: 0;
      margin-right: 0;
    }
  }
}
