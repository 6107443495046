@import '../../../theme/styles/variables/index';

.info-tags {
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  &__tag {
    position: relative;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: 24px;
    padding: 0 9px;
    color: $text-black;
    font-size: 14px;
    border: 1px solid $text-black;
    border-radius: 10px;
    outline: none;

    &--checked {
      padding-right: 24px;
      color: #fff;
      background-color: $color-primary;
    }

    &--clickable {
      cursor: pointer;
    }
  }

  &--multirow &__tag {
    margin-bottom: 8px;
  }

  &__tag:not(:last-child) {
    margin-right: 8px;
  }

  &__icon {
    position: absolute;
    top: 4px;
    right: 7px;
  }

  &__more {
    display: inline-block;
    line-height: 24px;
  }
}
