$text-black: #303633;
$text-default: #555D59;
$text-secondary: #87918C;
$text-disabled: #BCC2BF;
$text-grey: #D7DBD9;
$text-light-grey: #F8F8F8;

$color-primary-very-dark: #293D33;
$color-primary-dark: #41764C;
$color-primary-strong: #5EA66C;
$color-primary: #90C29A;
$color-primary-middle: #A1D3AB;
$color-primary-light: #D2EAD7;
$color-menu-hover: #EFF6F0;
$color-primary-extra-light: #F8FBF7;

$color-danger-light: #F79B9E;
$color-danger: #EC6061;
$color-danger-pressed: #AE3D46;

$color-link-main: #338FE4;
$color-link-light: #E6F7FF;

$color-yellow: #E2C15C;

/* stylelint-disable */
:export {
  textBlack: $text-black;
  textDefault: $text-default;
  textSecondary: $text-secondary;
  textDisabled: $text-disabled;
  textGrey: $text-grey;
  textLightGrey: $text-light-grey;

  colorPrimaryVeryDark: $color-primary-very-dark;
  colorPrimaryDark: $color-primary-dark;
  colorPrimaryStrong: $color-primary-strong;
  colorPrimary: $color-primary;
  colorPrimaryMiddle: $color-primary-middle;
  colorPrimaryLight: $color-primary-light;
  colorMenuHover: $color-menu-hover;

  colorDanger: $color-danger;
  colorDangerPressed: $color-danger-pressed;

  colorLinkMain: $color-link-main;
  colorLinkLight: $color-link-light;

  colorYellow: $color-yellow;
}
/* stylelint-enable */
