@import '../../../theme/styles/variables/index';

.view-order {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 16px;
  color: white;
  font-size: 16px;
  background-color: $color-primary-strong;
  border-radius: 4px;
  cursor: pointer;

  &__left {
    display: flex;
    align-items: center;
    gap: 16px;
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    background-color: white;
    border-radius: 50%;
  }

  &__text {
    font-weight: 500;
  }

  &__price {
    font-weight: 700;
  }
}
