@import '../../theme/styles/variables/index';

.profile-page {
  display: flex;
  background-color: $text-light-grey;

  &__tabs {
    min-width: 250px;
    margin-bottom: 3px;
  }

  &__content {
    width: 100%;
    padding: 20px;
  }

  &__form-container {
    position: relative;
    padding: 24px;
    background: #fff;
    border-radius: 4px;
    box-shadow: $box-shadow-light;
  }

  &__row:not(:last-child) {
    margin-bottom: 12px;
  }
}

@media screen and (max-width: 750px) {
  .profile-page {
    &__tabs {
      min-width: 180px;
    }

    &__content {
      padding: 12px;
    }

    &__form-container {
      margin: 0 0 12px !important;
    }
  }
}

@media screen and (max-width: $mobile-view-width) {
  .profile-page {
    &__tabs {
      display: none;
    }
  }
}
