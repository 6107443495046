@import '../../../theme/styles/variables/index';

.cancel-reservation-modal {
  width: 300px;

  &__question {
    margin-bottom: 20px;
    color: $text-default;
    font-size: 14px;
    line-height: 18px;
  }

  &__row {
    margin-bottom: 12px;
  }

  &__actions {
    margin-top: 16px;
  }
}
