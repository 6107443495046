@import '../../../theme/styles/variables/index';

.place-feedback-modal {
  width: 600px;
  padding: 0 !important;

  &__form {
    width: 100% !important;
    box-shadow: none !important;

    &::after {
      display: none !important;
    }
  }
}

@media screen and (max-width: $mobile-view-width) {
  .place-feedback-modal {
    padding: 24px 24px 0 !important;
  }
}
