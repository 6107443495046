@import '../../../theme/styles/variables/index';

$form-max-width: 767px;

.user-feedbacks {
  .profile-page__form-container {
    max-width: $form-max-width;

    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }

  &__more-button-container {
    max-width: $form-max-width;
  }

  .profile-page__content {
    flex-grow: 0;
    overflow: hidden;
  }

  &__place-card {
    width: 100%;
    padding: 0;
    border: none;
    box-shadow: none;

    .place-card__like {
      top: 5px;
      right: 2px;
    }
  }

  &__feedback {
    &::after {
      display: none;
    }
  }

  &__divider {
    width: calc(100% + 48px);
    height: 1px;
    margin: 24px -24px;
    background: $text-grey;
  }

  &__more-reviews {
    @include pagination-more; //stylelint-disable-line
  }

  &__empty {
    color: $text-black;
    font-size: 12px;
  }

  .feedback-card {
    position: relative;
    display: flex;

    &__image {
      flex: 0 0 auto;
      width: 76px;
      height: 76px;
      border-radius: 4px;
    }

    &__content {
      width: calc(100% - 88px);
      margin-left: 12px;
    }

    &__row {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      height: 26px;
    }

    &__summary {
      position: absolute;
      top: 4px;
      right: 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 160px;

      .svg-icon:hover .fill {
        fill: $color-primary-strong;
      }
    }

    &__name {
      color: $text-black;
      font-weight: 500;
      font-size: 16px;
    }

    &__price {
      margin-left: 12px;
    }
  }
}
