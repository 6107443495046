$header-height: 94px;

$transition: 0.2s ease-in-out 0s;

$max-container-width: 1250px;

$sliding-menu-padding: 24px;

$mobile-view-width: 600px;
$mobile-view-small-width: 450px;

$view-selector-width: 100px;

$box-shadow-light: 0 2px 6px rgba(0, 0, 0, 0.12);

/* stylelint-disable */
:export {
  headerHeight: $header-height;
  mobileViewWidth: $mobile-view-width;
}
/* stylelint-enable */
