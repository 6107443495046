@import '../../../theme/styles/variables/index';

.multi-select {
  position: relative;

  &__label {
    color: $text-black;
    line-height: 20px;
  }

  .react-select {
    &__control {
      min-height: 30px;
      border: 1px solid #d9d9d9;
      border-radius: 4px;
      cursor: pointer;

      &--is-focused {
        border: 1px solid #d9d9d9;
        box-shadow: none;
      }
    }

    &__placeholder {
      color: $text-disabled !important;
      font-size: 14px;
    }

    &__value-container {
      padding: 4px 8px;
    }

    &__indicator {
      padding: 0 8px;
      color: $text-secondary;

      &-separator {
        display: none;
      }
    }

    &__menu {
      margin-top: 0;
      cursor: pointer;
    }

    &__clear-indicator {
      display: none;
    }

    &__option {
      color: $text-default;
      font-size: 14px;
      text-align: center;

      &:not(:last-child) {
        background-color: #fff;
        border-bottom: 1px solid $text-grey;
      }
    }

    &__multi-value {
      height: 20px;
      padding: 1px 2px;
      color: #fff;
      background-color: $color-primary;
      border-radius: 10px;
      box-shadow: $box-shadow-light;

      &__remove:hover {
        color: #fff;
        background-color: $color-primary;
        border-radius: 10px;
      }

      &__label {
        height: 20px;
        color: #fff;
        font-size: 12px;
      }
    }
  }
}
