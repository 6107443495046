@import '../../theme/styles/variables/index';

.favorite-places {
  &__summary {
    position: relative;
    min-height: 44px;
    padding: 8px 24px;
    color: $text-default;
    font-size: 12px;
    line-height: 24px;
    box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
  }

  &__cards {
    padding: 24px;
    background-color: $color-menu-hover;
  }

  &__cards-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-content: flex-start;
    align-items: flex-start;
  }

  &__card {
    display: block;
  }
}

@media screen and (max-width: 1060px) {
  .favorite-places {
    &__cards-container {
      display: block;
    }
  }
}

@media screen and (max-width: 479px) {
  .favorite-places {
    &__cards {
      padding: 10px;
    }
  }
}
