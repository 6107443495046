.top-center {
  width: auto !important;
  min-width: 300px !important;
  margin-left: 0 !important;
  transform: translateX(-50%);

  .rrt-success,
  .rrt-info,
  .rrt-error {
    background-color: #fff !important;
  }

  .rrt-middle-container {
    width: auto !important;
    margin: 15px 0 11px 38px !important;
    padding: 0 16px 0 0 !important;
    font-size: 14px !important;
    font-family: 'Onest', sans-serif !important;
  }

  .close-toastr {
    display: none;
  }

  .toastr-icon {
    width: 14px !important;
    height: 14px !important;
    fill: #9ed84d !important;
  }

  .rrt-holder {
    width: 38px !important;
    margin-top: -28px !important;
  }

  .rrt-left-container {
    width: 38px !important;
  }

  .rrt-title {
    font-weight: 400 !important;
  }
}

.toastr {
  min-height: 40px !important;
  margin: 8px 0 !important;
  color: #595959 !important;
}
