@import '../../../theme/styles/variables/index';

.avatar {
  flex: 0 0 auto;
  overflow: hidden;
  border-radius: 50%;

  &--clickable {
    cursor: pointer;
  }

  &__notifications {
    @include top-menu-notifications; // stylelint-disable-line

    top: -2px;
    left: 28px;
  }

  &__image {
    width: 100%;
    height: 100%;
  }

  &__generated {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    color: #fff;
    font-weight: 600;
    font-size: 18px;
    background-color: $color-primary;
  }
}
