@import '../../../theme/styles/variables/index';

.reservation-details {
  display: flex;
  flex-wrap: wrap;
  column-gap: 12px;
  row-gap: 5px;
  color: $text-default;
  line-height: 22px;

  &--full-width {
    width: 100%;
  }

  &__item {
    display: flex;
    align-items: center;
    font-size: 14px;
  }

  &__icon {
    margin-right: 5px;
  }
}
