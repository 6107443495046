@import '../../../theme/styles/variables/index';

.paid-services-modal {
  width: 100%;
  padding: 0 !important;
  background: rgba(0, 0, 0, 0.7) !important;
  height: 90vh;

  .modal__content {
    height: 100%;
  }

  &__slider {
    position: relative;
    width: 90vw;
    height: 100%;
    min-width: 300px;
    max-width: 1250px;
    margin: 0 auto;

    &__image {
      min-height: 200px;
    }
  }

  .slick {
    &-dots {
      bottom: 10px !important;

      li {
        width: 76px;
        height: 76px;
        border-radius: 8px;
        overflow: hidden;
        border: 1px solid transparent;
      }

      .slick-active {
        border-color: white;
      }
    }

    &-next {
      right: 25px !important;
    }

    &-prev {
      left: 25px !important;
      z-index: 1;
    }
  }
}
