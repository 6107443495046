@import '../../../theme/styles/variables/index';

.dish-quantity {
  &__empty {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    background: $color-primary;
    color: #fff;
    font-size: 24px;
    font-weight: 600;
    cursor: pointer;
    border-radius: 32px;
  }

  &__select {
    display: flex;
    height: 30px;
    font-size: 30px;
    line-height: 30px;
    color: white;
    overflow: hidden;

    &--left {
      padding: 0 7px;
      background-color: $color-primary;
      cursor: pointer;
      border-top-left-radius: 50%;
      border-bottom-left-radius: 50%;
      font-size: 30px;
    }

    &--quantity {
      padding: 0 8px;
      font-size: 16px;
      color: $text-black;
      border: 1px solid $color-primary;
    }

    &--right {
      padding: 0 7px;
      background-color: $color-primary;
      cursor: pointer;
      border-top-right-radius: 50%;
      border-bottom-right-radius: 50%;
    }
  }
}
