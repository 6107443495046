@import '../../../theme/styles/variables/index';

.price-indicator {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 14px;

  .item {
    &--filled {
      color: $text-black;
    }

    &--empty {
      color: $text-grey;
    }
  }
}
