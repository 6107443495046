@import '../../../theme/styles/variables/index';

.allow-geolocation-modal {
  width: 300px;

  &__text {
    color: $text-default;
    font-size: 13px;
    line-height: 16px;
    text-align: center;
  }

  &__actions {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
}
