@import '../../theme/styles/variables/index';

.search-page {
  position: relative;

  &__top {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 287px;
    padding: 16px;
    background-image: url('../../theme/images/gpt_background.jpg');
    background-position: center;
    background-size: cover;

    .content {
      max-width: 650px;
      padding: 16px;
    }
  }

  &__top-label {
    margin-bottom: 32px;
    color: white;
    font-size: 36px;
    font-weight: 600;
    text-align: center;
  }

  &__content {
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    gap: 20px;
    padding-top: 48px;
  }

  //&__left {
  //  flex: 1 0 40%;
  //  max-width: 100%;
  //}

  &__map {
    position: sticky;
    top: 94px;
    flex-grow: 1;
  }

  &__filters {
    .row {
      display: flex;
      align-items: center;

      &:not(:last-child) {
        margin-bottom: 16px;
      }
    }

    .search-content {
      display: flex;
      //justify-content: flex-end;
    }
  }

  &__filters-content {
    padding: 16px;
  }

  &__tags-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 16px;
  }

  &__query-and-tags {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  &__query-result {
    display: inline-block;
    flex: 0 0 auto;
  }

  &__found-tags {
    display: inline-block;
    flex: 1 0 auto;
    width: 100%;
    margin-left: 16px;
  }

  &__no-results {
    color: $text-black;
    padding: 16px;
  }

  &__results {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0;

    &--inline {
      display: block;
      padding: 0;
    }
  }

  &__loading-more {
    margin-top: 20px;
  }

  &--mobile {
    .search-page {
      &__content {
        padding-top: 16px;
        gap: 0;
      }

      &__left {
        flex-basis: 100%;
        order: 1;
        max-width: unset;
      }

      &__map {
        position: static;
        flex-basis: 100%;
        height: 400px;
      }
    }
  }
}

@media screen and (max-width: $mobile-view-width) {
  .search-page {
    &__top-label {
      font-size: 32px;
    }
  }
}
