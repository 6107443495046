@import '../../../theme/styles/variables/index';

.text-link {
  display: inline-flex;
  align-items: center;
  text-decoration: none;
  cursor: pointer;

  &:hover,
  &:active,
  &:focus {
    text-decoration: underline;
  }

  &__icon {
    margin-right: 10px;
  }

  &--default {
    color: $color-link-main;
  }

  &--text {
    color: $text-black;

    &:visited {
      color: $text-black;
    }

    &:hover,
    &:active,
    &:focus {
      color: $text-black;
    }
  }

  &--success {
    color: $color-primary-strong;

    &:visited {
      color: $color-primary-strong;
    }

    &:hover,
    &:active,
    &:focus {
      color: $color-primary-dark;
      text-decoration: none;
    }
  }

  &--danger {
    color: $color-danger;

    &:visited {
      color: $color-danger;
    }

    &:hover,
    &:active,
    &:focus {
      color: $color-danger-pressed;
      text-decoration: none;
    }
  }

  &--small {
    font-size: 12px;
  }

  &--medium {
    font-size: 14px;
  }

  &--large {
    font-size: 16px;
  }

  &[disabled] {
    color: $text-disabled;
    cursor: not-allowed;
  }
}
