@import '../../../theme/styles/variables/index';

$country-code-width: 73px;

.react-tel-input {
  .form-control {
    width: 100% !important;
    height: 42px !important;
  }

  .search-box {
    width: 200px;
  }

  @media screen and (min-width: $mobile-view-width) {
    .form-control {
      width: $country-code-width;
      height: 42px !important;
    }

    .search-box {
      width: 170px !important;
    }

    .country-list {
      margin: 10px 0 !important;
      width: 225px !important;
      max-height: 180px !important;
    }
  }
}
