@import '../../../theme/styles/variables/index';

.new-password-modal {
  width: 290px;

  .modal__content {
    overflow: initial;
  }

  &__text {
    margin-bottom: 24px;
    color: $text-default;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
  }

  &__row {
    margin-bottom: 16px;
  }

  &__back {
    text-align: center;
  }
}
