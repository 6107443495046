@import '../../../theme/styles/variables/index';

.dish-card {
  display: flex;
  gap: 8px;
  padding: 20px 0;
  border-bottom: 1px solid #D9D9D9;

  &__photo-wrapper {
    flex: 0 0 50px;
    cursor: pointer;
  }

  &__photo {
    width: 50px;
    height: 50px;
    border-radius: 16px;
    object-fit: contain;
  }

  &__content {
    flex-grow: 1;
  }

  &__actions {
    flex: 0 0 120px;
    display: inline-flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 8px;
  }

  &__name-row {
    display: flex;
    align-items: center;
    gap: 4px;
    margin-bottom: 12px;
  }

  &__name {
    font-size: 18px;
    font-weight: 500;
    cursor: pointer;
  }

  &__description {
    font-size: 14px;
    line-height: 150%;
    margin-bottom: 12px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  &__tags {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  &__weight {
    color: $text-secondary;
    font-size: 16px;
  }

  &__tag {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.12);
  }

  &__price {
    font-size: 18px;
    font-weight: 600;
    color: $color-primary-strong;
  }

  &__like-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    background: #fff;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.12);
    cursor: pointer;

    &--liked {
      background-color: $color-primary;
    }
  }

  &__like-icon {
    position: relative;
    top: 2px;
  }
}
