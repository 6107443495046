@import 'variables/index';

* {
  box-sizing: border-box;
}

body {
  color: $text-black;
  font-size: 12px;
  font-family: 'Onest', sans-serif;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background: $color-primary-light;
  border-radius: 6px;
}

::-webkit-scrollbar-thumb {
  background: rgba($color-primary, 0.25);
  border-radius: 6px;
}

input,
textarea,
button {
  font-family: inherit;
}

.content-container {
  max-width: $max-container-width;
  margin: 0 auto;
}

input {
  background-clip: padding-box !important;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.modal-open,
.sidebar-open {
  overflow-y: hidden;
}
