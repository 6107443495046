@import '../../../theme/styles/variables/index';

.pagination {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: 10px 0 24px 0;
  text-align: center;

  .break-me {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    margin-left: 8px;
    color: $color-primary;
    font-weight: bold;
    font-size: 14px;
  }

  &__list {
    display: flex;
  }

  &__item {
    display: block;
    width: 32px;
    height: 32px;
    color: $text-default;
    font-size: 14px;
    line-height: 32px;
    text-align: center;
    border: 1px solid $text-grey;
    border-radius: 4px;
    outline: none;
    cursor: pointer;

    &:nth-child(n+1) {
      margin-left: 8px;
    }
  }

  &--active > .pagination__item {
    color: $color-primary;
    border-color: $color-primary;
  }

  .disabled .pagination__item {
    cursor: not-allowed;
    opacity: 0.5;
  }
}
