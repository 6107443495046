@import '../../theme/styles/variables/index';

$right-content-width: 350px;
$detailed-rating-width: 230px;

.place-info {
  &__content {
    display: flex;
    justify-content: space-between;
    max-width: $max-container-width;
    margin: 0 auto;
    padding: 24px 16px 24px 24px;
    overflow-x: hidden;
  }

  &__left {
    width: calc(100% - #{$right-content-width} - 40px);
  }

  &__left-top {
    display: flex;
    align-items: flex-start;
  }

  &__right {
    width: $right-content-width;
  }

  &__tags-top {
    width: calc(100% - #{$detailed-rating-width} - 40px);
  }

  &__detailed-rating {
    width: $detailed-rating-width;
    margin-right: auto;
    margin-bottom: 20px;
    margin-left: auto;
  }

  &__quick-reservation {
    max-width: 350px;
    margin-bottom: 20px;
  }

  .tags {
    &__header {
      position: relative;
      display: flex;
      align-items: center;
      gap: 12px;
      width: max-content;
      max-width: 100%;
      margin-bottom: 12px;
      padding-right: 35px;
      color: $text-black;
      font-weight: 500;
      font-size: 24px;

      .select__options {
        top: 39px;
        min-width: 250px;
      }

      .select__option {
        font-size: 14px;
      }

      .select__value {
        font-size: 24px;
        color: $text-black;
      }
    }

    &__item {
      position: relative;
      margin-bottom: 16px;
      padding-left: 24px;

      &--already-left {
        color: $text-disabled;
        font-size: 16px;
      }

      .form-icon {
        position: absolute;
        top: -5px;
        left: 170px;
        width: 24px;
        padding: 5px;
        background-color: $color-primary-strong;
        border-radius: 4px;
        cursor: pointer;

        &--mobile {
          margin-right: 8px;
        }
      }
    }

    &__top-item {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      max-width: 430px;
      color: $text-default;
      font-size: 16px;
    }

    &__item-heading {
      color: $text-default;
      font-weight: 500;
      font-size: 16px;
    }

    &__item-content {
      margin-top: 8px;
      color: $text-default;
      font-size: 16px;
      line-height: 22px;
      white-space: pre-line;
    }

    &__icon {
      position: absolute;
      top: -1px;
      left: 0;
    }

    &__like {
      width: 22px;
      cursor: pointer;
    }

    &__payments {
      display: flex;
      align-items: center;
    }

    &__highlight {
      color: $color-primary-strong;

      &--danger {
        color: $color-danger-pressed;
      }
    }

    &__flex-center {
      display: flex;
      align-items: center;
    }

    &__quantity {
      margin: 0 10px;
      color: $color-primary;
      font-weight: 500;
      font-size: 16px;
    }

    &__comment-heading {
      display: flex;
      flex-wrap: wrap;
    }
  }

  .leave-feedback-button {
    width: calc(100% + 23px);
    margin-top: 8px;
    margin-left: -23px;
  }

  &__feedback-form {
    margin-top: 16px;
  }

  &__feedback {
    &:first-child {
      margin-top: 16px;
    }

    &:not(:last-child) {
      margin-bottom: 24px;
    }
  }

  &__contacts {
    margin-top: 40px;
  }

  .payment-tag {
    line-height: 16px;
    text-align: center;

    &__text {
      color: $text-default;
      font-size: 14px;
      line-height: 20px;
    }

    &:not(:last-child) {
      margin-right: 16px;
    }
  }

  &__more-reviews {
    @include pagination-more; //stylelint-disable-line
  }

  &__switch-container {
    position: relative;
    max-width: $max-container-width;
    margin: 0 auto;
  }

  &__menu-switch {
    position: absolute;
    bottom: 20px;
    left: 25px;
  }

  &__menu-categories {
    margin-left: 40px;
    margin-right: 40px;

    &--subcategories {
      margin-top: 12px;
    }

    .slick-arrow {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 34px;
      height: 34px;
      border-radius: 50%;
      color: white;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.12);

      &::before {
        font-family: unset;
        font-size: 18px;
        font-weight: bold;
        color: $text-default;
      }
    }

    .slick-next {
      right: -40px;

      &::before {
        content: '→';
      }
    }

    .slick-prev {
      left: -40px;

      &::after {
        content: '←';
      }
    }
  }

  &__menu-category {
    height: 40px;
    margin-right: 10px;
    display: inline-flex;
    align-items: center;
    gap: 5px;
    min-width: unset;
    padding: 0 16px;
    border-radius: 50px;
    border: none;
    background-color: $color-primary;

    &.button--flat {
      background-color: $text-grey;
    }

    &--subcategory {
      height: 34px;
      background-color: $color-primary;

      &.button--flat {
        background: none;
      }
    }
  }

  &__menu-dishes {
    margin-top: 24px;
  }

  &__view-order {
    margin-bottom: 32px;
  }
}

@media screen and (max-width: 1199px) {
  .place-info {
    &__tags-top {
      width: 100%;
    }
  }
}

@media screen and (max-width: 991px) {
  .place-info {
    &__content {
      display: block;
    }

    &__left {
      width: 100%;
    }

    &__right {
      width: 100%;
    }

    &__quick-reservation {
      margin-right: auto;
      margin-left: auto;
    }

    &__view-order {
      position: fixed;
      left: 16px;
      bottom: -20px;
      right: 16px;
      z-index: 9999;
    }
  }
}

@media screen and (max-width: $mobile-view-width) {
  .place-info {
    &__content {
      padding: 12px 8px 0 12px;
    }

    .tags {
      &__margin-bottom {
        margin-bottom: 8px;
      }

      &__item-content {
        margin-left: -23px;
      }
    }

    &__feedback {
      margin-bottom: 12px !important;
    }

    &__menu-categories {
      margin-left: 0;
      margin-right: 0;
    }

    &__switch-container {
      bottom: 20px;
    }
  }
}

@media screen and (max-width: 479px) {
  .place-info {
    .tags {
      &__top-item {
        display: block;
      }

      &__item {
        &--already-left {
          flex: 1 0 100%;
          margin-top: 8px;
          margin-left: -23px;
        }
      }
    }
  }
}
