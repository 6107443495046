@import '../../../theme/styles/variables/index';

.select {
  padding: 0 30px 0 10px;
  overflow: hidden;
  white-space: nowrap;
  outline: none;
  border-radius: 8px;
  cursor: pointer;
  line-height: 40px;

  &__wrapper {
    position: relative;
    display: inline-block;
    background-color: $text-light-grey;

    &:hover {
      background-color: $color-menu-hover;
    }

    .front-icon {
      position: absolute;
      top: 8px;
      left: 8px;
    }
  }

  &__chevron {
    position: absolute !important;
    top: 17px;
    right: 10px;
    pointer-events: none;
  }

  &__value {
    color: $text-default;
    font-size: 18px;
  }

  &__placeholder {
    color: $text-disabled;
  }

  &--has-icon {
    padding-left: 32px;
  }

  &__options {
    position: absolute;
    z-index: 5000;
    max-height: 432px;
    overflow-y: auto;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);

    &--parent {
      top: 23px;
      right: 0;
      left: 0;
    }

    &--left {
      left: 0;
      width: max-content;
    }

    &--right {
      right: 0;
      width: max-content;
    }
  }

  &__label {
    color: $text-black;
    font-size: 14px;
    line-height: 20px;
  }

  &--has-label {
    .front-icon {
      top: 28px;
    }

    .select__chevron {
      top: 33px;
    }
  }

  &--full-width {
    width: 100%;
  }

  &__option {
    position: relative;
    width: 100%;
    padding: 0 8px;
    color: $text-default;
    line-height: 2.5em;
    outline: none;
    cursor: pointer;

    &:hover {
      background-color: $color-menu-hover;
    }

    &:not(:last-child) {
      border-bottom: 1px solid $text-grey;
    }

    &--selected {
      background-color: $color-menu-hover;
    }
  }
}
