@import '../../theme/styles/variables/index';

.finish-reservation {
  display: flex;
  justify-content: space-between;
  max-width: $max-container-width;
  margin: 0 auto;
  padding: 24px 40px;

  &__back {
    color: $text-secondary !important;
  }

  &__reservation-info {
    width: 470px;
  }

  &__contacts {
    width: 350px;
  }

  .reservation-info {
    &__back {
      margin-bottom: 16px;
    }

    &__almost {
      margin-bottom: 16px;
      color: $text-default;
      font-weight: 500;
      font-size: 16px;
    }

    &__details {
      display: flex;
    }

    &__photo {
      flex: 0 0 44px;
      height: 44px;
      margin-right: 12px;
      overflow: hidden;
      border-radius: 4px;
    }

    &__resto-name {
      color: $text-black;
      font-weight: 500;
      font-size: 16px;
      line-height: 22px;
    }

    .selected-tables {
      margin: 12px 0;
      padding: 16px;
      display: flex;
      flex-direction: column;
      gap: 16px;
      border-radius: 8px;
      background-color: $color-primary-extra-light;
      font-size: 14px;

      &__row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 8px;
      }

      &__price {
        font-size: 16px;
        font-weight: 600;
        color: $color-primary;
      }

      &__divider {
        height: 1px;
        background-color: $text-grey;
      }
    }

    &__timer {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      margin: 10px 0 12px;
      padding: 0 20px;
      color: $text-black;
      line-height: 36px;
      background-color: $color-menu-hover;
      border-radius: 4px;
      font-size: 14px;
    }

    .timer {
      &__icon {
        margin-right: 9px;
      }

      &__time {
        display: inline-block;
        font-weight: 500;
      }
    }

    .form {
      &__row {
        display: flex;
        justify-content: space-between;
        align-items: center;

        &:not(:last-child) {
          margin-bottom: 16px;
        }

        &--block {
          display: block;
        }
      }

      &__column {
        width: 48%;
      }

      &__or {
        margin: 0 auto;
        color: $text-default;
        font-size: 16px;
        text-align: center;
      }
    }

    &__row {
      margin-top: 12px;

      &:not(:last-child) {
        margin-bottom: 12px;
      }
    }

    .select-hall {
      margin-bottom: 8px;
      font-size: 20px;
      font-weight: 600;
      line-height: 24px;

      &__items {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        row-gap: 8px;
      }
    }

    &__busy-hours {
      padding: 5px 20px;
      font-size: 14px;
      line-height: 20px;
      background-color: rgba($color-yellow, 0.7);
      border-radius: 4px;
      white-space: pre-line;
    }

    .selected-deposits {
      padding: 5px 20px;
      font-size: 14px;
      background-color: $text-light-grey;
      border-radius: 4px;
      margin-bottom: 16px;
    }

    .selected-deposit {
      padding-top: 16px;
      padding-bottom: 16px;
      border-bottom: 1px solid #d9d9d9;

      &__price {
        font-size: 16px;
        font-weight: 600;
        color: $color-primary-strong;
      }
    }

    .deposit-total {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 16px;
      margin-bottom: 16px;
      font-size: 16px;
      font-weight: bold;
    }

    .deposits-required-error {
      margin-bottom: 16px;
      color: $color-danger;
      font-size: 12px;
    }
  }

  &__submit .button {
    padding: 20px 28px;
  }

  .certificate {
    &__search {
      position: relative;
    }

    &__search-button {
      position: absolute;
      top: 5px;
      right: 5px;
    }

    &__card {
      margin-bottom: 12px;
      padding: 15px;
      display: flex;
      align-items: flex-start;
      gap: 15px;
      border: 1px solid $text-secondary;
      border-radius: 8px;
    }

    &__icon-wrapper {
      flex-shrink: 0;
      border-radius: 50%;
      width: 48px;
      height: 48px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $text-black;
    }

    &__icon {
      width: 24px;

      &--clickable {
        cursor: pointer;
      }
    }

    &__center {
      flex-grow: 1;
    }

    &__name {
      font-size: 12px;
      color: $text-secondary;
    }

    &__id {
      margin-top: 15px;
      font-size: 16px;
    }

    &__remove {
      flex-shrink: 0;
    }
  }
}

@media screen and (max-width: 940px) {
  .finish-reservation {
    display: block;

    &__reservation-info {
      width: 100%;
    }

    &__contacts {
      width: 100%;
      margin-top: 40px;
    }
  }
}

@media screen and (max-width: 560px) {
  .finish-reservation {
    padding: 12px 20px;

    .reservation-info {
      &__timer {
        flex-wrap: wrap;
        justify-content: center;
        min-height: 36px;
        padding-top: 6px;
        padding-bottom: 6px;
        line-height: 24px;
      }
    }

    .form {
      &__row {
        display: block !important;
      }

      &__column {
        width: 100% !important;
        margin-bottom: 16px;
      }
    }
  }
}
