@import '../../../theme/styles/variables/index';

.place-location-modal {
  width: 800px;
  height: 400px;
  padding: 6px !important;

  .modal__content {
    height: 100%;
  }
}
