.cart-modal {
  width: 350px;

  &__empty {
    margin: 4px 0;
  }

  &__total {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 12px;
    margin-bottom: 4px;
    font-size: 16px;
    font-weight: 700;
  }

  &__clear {
    margin-top: 20px;
    text-align: center;
  }

  .dish-card__name {
    font-size: 16px;
  }

  .dish-quantity__select {
    margin-top: 16px;
  }
}
