@import '../../../theme/styles/variables/index';

.place-contacts {
  &__item {
    font-size: 16px;
    line-height: 22px;

    &:not(:last-child) {
      margin-bottom: 24px;
    }
  }

  &__heading {
    color: $text-default;
    font-weight: 500;
  }

  &__data {
    color: $text-secondary;
    font-size: 16px;
  }

  &__map {
    width: 100%;
    height: 240px;
    margin-top: 12px;
    overflow: hidden;
    border-radius: 4px;
  }

  .contact-item {
    display: flex;
    align-items: center;
    overflow: hidden;

    &__icon {
      flex: 0 0 16px;
      margin-right: 8px;
    }

    &__reference {
      @include text-overflow-ellipsis; // stylelint-disable-line

      display: block;
      margin-right: 8px;
    }
  }
}
