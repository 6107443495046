@import '../../../theme/styles/variables/index';

.modal-overlay {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 10000;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.7);

  .modal {
    max-width: 90vw;
    max-height: 90vh;
    padding: 24px;
    overflow-x: hidden;
    background: #fff;
    border-radius: 4px;
    outline: none;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);

    &__title {
      margin-bottom: 16px;
      color: $text-default;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
    }

    &__content {
      overflow-x: hidden;
      overflow-y: auto;
    }
  }
}
