@import '../../../theme/styles/variables/index';

.login-register-modal {
  width: 300px;

  &__row {
    margin-bottom: 12px;

    &--small {
      margin-bottom: 8px;
    }

    &--large {
      margin-bottom: 16px;
    }
  }

  &__or {
    color: $text-default;
    font-size: 14px;
    text-align: center;
  }

  &__forgot-password {
    line-height: 20px;
    text-align: right;
  }

  &__forgot-password-link {
    color: $text-secondary;
    text-decoration: none;
  }

  &__actions {
    margin-top: 16px;
  }

  &__new-user {
    color: $text-secondary;
    font-size: 12px;
    line-height: 20px;
  }
}
