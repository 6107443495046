@import '../../theme/styles/variables/index';

.footer {
  font-size: 16px;
  background-color: $color-primary-very-dark;
  box-shadow: 0 -2px 2px rgba(0, 21, 41, 0.08);
  color: #fff;

  &__content {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 32px 16px;
    text-align: center;
  }

  &__col {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    line-height: 16px;
  }

  a {
    color: #fff !important;
    text-decoration: none;
  }

  .text-disabled {
    color: $text-disabled;
  }

  &__logo {
    text-align: center;
  }
}

@media screen and (min-width: 800px) {
  .footer {
    &__content {
      position: relative;
      flex-direction: row;
      text-align: left;
    }

    &__col {
      align-items: flex-start;
    }
  }
}
