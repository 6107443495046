@import '../../theme/styles/variables/index';

.feedback-form {
  position: relative;
  width: 90%;
  margin: 8px 0 16px;
  padding: 9px 16px;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: $box-shadow-light;

  &__heading {
    margin-bottom: 10px;
    color: $text-default;
    font-weight: 500;
    font-size: 14px;
  }

  &__rating {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    width: 70%;
    max-width: 500px;
    margin: 20px 0 15px 0;
  }

  &__left {
    min-width: 180px;
    margin-right: 20px;
  }

  &__right {
    min-width: 180px;
  }

  &__stat {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 12px;
    color: $text-default;
    font-weight: 500;
    font-size: 12px;
  }

  &__buttons {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 12px;
  }

  &__cancel {
    margin-right: 20px;
  }
}

@media screen and (max-width: $mobile-view-width) {
  .feedback-form {
    width: 100%;
    padding: 0 0 16px;
    box-shadow: none;

    &::after {
      @include grey-border; //stylelint-disable-line

      bottom: 0;
    }

    &__heading {
      font-size: 16px;
    }

    &__stat {
      &--name {
        font-size: 14px;
      }
    }

    &__buttons {
      display: block;
    }

    &__cancel {
      position: absolute;
      top: 0;
      right: 0;
      margin-right: 0;
    }
  }
}
