@import '../../../theme/styles/variables/index';

.time-selector__wrapper {
  position: relative;
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 16px;

  .front-icon {
    width: 24px;
  }

  .time-selector {
    width: 85px;
    height: 40px;
    padding: 0 10px;
    color: $text-default;
    font-size: 18px;
    line-height: 30px;
    border: none;
    background-color: $text-light-grey;
    border-radius: 8px;
    outline: none;
    cursor: pointer;

    &__chevron {
      position: absolute !important;
      top: 17px;
      right: 10px;
      pointer-events: none;
    }

    &--full-width {
      width: 100%;
    }

    &:hover {
      background-color: $color-menu-hover;
    }
  }
}

#time-selector-wrapper {
  .react-datepicker {
    font-size: 14px !important;
    font-family: 'Onest', sans-serif;

    &-wrapper {
      width: 100%;
    }

    &__triangle {
      display: none;
    }

    &-popper {
      z-index: 5000 !important;
    }

    &__header {
      display: none !important;
    }

    &__time-list-item {
      display: flex;
      justify-content: center;
      align-items: center;
      height: unset !important;
      padding: 0 8px !important;
      color: $text-default;
      font-size: 14px;
      line-height: 2.5em;
      outline: none;
      cursor: pointer;

      &--selected {
        color: #fff;
      }

      &:hover {
        background-color: $color-menu-hover !important;
      }

      &--selected:hover {
        background-color: #216ba5 !important;
      }

      &:not(:last-child) {
        border-bottom: 1px solid $text-grey !important;
      }

      &--disabled {
        display: none;
      }
    }
  }
}
