@import '../../../theme/styles/variables/index';

.star-rating {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 700;
  color: $text-black;

  &--clickable {
    cursor: pointer;
  }

  &__rating {
    width: 24px;
  }

  &__reviews {
    margin-left: 5px;
    font-size: 12px;
  }
}
