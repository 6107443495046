@import "colors";

@mixin text-overflow-ellipsis { //stylelint-disable-line
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

@mixin grey-border { //stylelint-disable-line
  position: absolute;
  right: -50px;
  left: -50px;
  z-index: 1;
  display: block;
  height: 1px;
  background-color: $text-grey;
  content: '';
}

@mixin pagination-more { //stylelint-disable-line
  display: block;
  margin: 0 auto;
  color: $text-default;
  background-color: #fff;
  border: 1px solid $text-disabled;

  &:hover,
  &:focus,
  &:active {
    background-color: $text-grey;
  }
}

@mixin top-menu-notifications { //stylelint-disable-line
  position: absolute;
  top: -10px;
  left: 10px;
  z-index: 100;
  padding: 1px 4px;
  color: #fff;
  font-size: 9px;
  line-height: 10px;
  text-align: center;
  background-color: $color-primary-strong;
  border: 1px solid #fff;
  border-radius: 8px;
  pointer-events: none;
}
