@import '../../../theme/styles/variables/index';

.change-city-popup {
  top: 50px;
  left: 200px;
  padding: 24px !important;

  &__guess {
    margin-bottom: 16px;
    font-weight: 500;
    font-size: 18px;
  }

  &__yes {
    margin-right: 16px;
  }

  &__options {
    display: flex;
    justify-content: space-between;
  }
}

@media screen and (max-width: $mobile-view-width) {
  .change-city-popup {
    left: 24px;
  }
}
