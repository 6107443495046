@import '../../../theme/styles/variables/index';

.svg-icon {
  position: relative;
  display: inline-block;
  width: 16px;

  svg {
    display: block;
    width: 100%;
  }

  &__chevronDown {
    width: 10px;
  }

  &__check {
    width: 12px;
  }

  &__confirm,
  &__processing,
  &__info,
  &__cancel {
    width: 14px;
  }

  &__logo {
    width: 35px;
  }

  &__top {
    width: 24px;
  }

  &__hot,
  &__vegetarian {
    width: 13px;
  }

  &__heart {
    width: 24px;
  }

  &__calendar {
    width: 18px;
  }
}
