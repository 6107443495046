@import '../../theme/styles/variables/index';

.buy-certificate {
  &__container {
    max-width: 600px;
    margin: 0 auto;
    padding: 24px;
  }

  &__title {
    margin-bottom: 16px;
    display: flex;
    align-items: center;
    gap: 16px;
    font-size: 18px;
    font-weight: 600;
  }

  &__title-icon {
    transform: rotate(90deg);
    cursor: pointer;
  }

  &__form {
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  &__check-phone {
    font-size: 12px;
    line-height: 15px;
    color: $text-secondary;
  }
}
