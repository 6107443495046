@import '../../../theme/styles/variables/index';

.promo-success-modal {
  max-width: 400px !important;

  &__message {
    font-size: 14px;
    line-height: 20px;
  }
}
