@import '../../../theme/styles/variables/index';

.app-logo {
  display: inline-flex;
  align-items: center;
  min-width: 0;

  &__img {
    flex-shrink: 1;
    width: 124px;
  }

  &__ua {
    width: 50px;
  }
}
