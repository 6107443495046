@import '../../../theme/styles/variables/index';

.select-quantity {
  &__select {
    flex-shrink: 0;
    display: flex;
    height: 30px;
    font-size: 30px;
    line-height: 30px;
    color: white;
    overflow: hidden;

    &--left {
      padding: 0 7px;
      background-color: $color-primary;
      cursor: pointer;
      border-top-left-radius: 50%;
      border-bottom-left-radius: 50%;
      font-size: 30px;
    }

    &--quantity {
      padding: 0 8px;
      font-size: 16px;
      color: $text-black;
      border: 1px solid $color-primary;
    }

    &--right {
      padding: 0 7px;
      background-color: $color-primary;
      cursor: pointer;
      border-top-right-radius: 50%;
      border-bottom-right-radius: 50%;
    }
  }
}

.disabled-remove-select {
  pointer-events: none;
  opacity: 0.5;
}

.disabled-left-border {
  border-left: 1px solid rgba($color-primary, 0.5);
}
