@import '../../../theme/styles/variables/index';

.certificates-modal {
  width: 750px;
  padding: 0 !important;

  .modal__title {
    padding: 16px 0;
    margin: 0;
    border-bottom: 1px solid $text-grey;
  }

  .modal__content {
    padding: 24px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    background-color: $text-light-grey;
  }
}
