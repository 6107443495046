@import '../../../theme/styles/variables/index';

.input-group {
  position: relative;
  display: flex;
  align-items: flex-end;

  &--full-width {
    justify-content: center;
    width: 100%;

    .input-group-item__wrapper {
      flex-grow: 1;
    }

    .input-group-item {
      width: 100%;
    }
  }

  &--has-error {
    .input-group-item {
      border-color: $color-danger !important;
    }
  }

  .input-group-item {
    border-width: 0;
    border-radius: unset !important;

    &__wrapper {
      vertical-align: top;

      &:last-child .input-group-item {
        border-width: 1px 1px 1px 0;
        border-top-right-radius: 4px !important;
        border-bottom-right-radius: 4px !important;
      }

      &:first-child .input-group-item {
        border-width: 1px;
        border-top-left-radius: 4px !important;
        border-bottom-left-radius: 4px !important;
      }

      &:not(:first-child):not(:last-child) .input-group-item {
        border-width: 1px 1px 1px 0;
      }
    }
  }

  &__error {
    position: absolute;
    bottom: -15px;
    color: $color-danger;
    font-size: 12px;
  }
}
