@import '../../theme/styles/variables/index';

.quick-reservation {
  position: relative;
  padding: 16px 10px 8px 20px;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);

  &__heading {
    color: $text-default;
    font-weight: 400;
    font-size: 16px;

    &--book {
      margin-bottom: 15px;
      font-weight: 700;
      font-size: 18px;
    }

    &--time {
      margin: 20px 0 8px;
    }
  }

  &__selects {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 12px;
  }

  &__select {
    border: none;
  }

  &__spacer {
    display: none;
  }

  &__no-options {
    color: $text-default;
    font-size: 14px;
    line-height: 20px;
  }

  &__loading-wrapper {
    position: relative;
    height: 80px;
  }

  .show-375 {
    display: none;
  }

  &__promo-label {
    position: absolute;
    top: -4px;
    right: 16px;
  }
}
