@import '../../theme/styles/variables/index';

.search-bar {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 16px;

  &__view-selector {
    margin-left: 12px;
  }
}
