@import '../../theme/styles/variables/index';

.app-header {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 5000;
  height: $header-height;
  background-color: #fff;
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);

  &__top-bar {
    background-color: $text-light-grey;
    box-shadow: inset 0 -1px 0 0 $text-grey + aa;

    &-content {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      height: 30px;
      padding: 0 16px;
    }

    &-link {
      color: $text-black !important;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }

      &:not(:last-child) {
        margin-right: 24px;
      }
    }
  }

  &__content {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: calc(#{$header-height} - 30px);
    padding: 0 16px;
  }

  &__left {
    display: inline-flex;
    align-items: center;
    flex-shrink: 1;
    min-width: 0;
  }

  &__city-select {
    margin-left: 5px;
  }

  &__right {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &__sign-in {
    display: inline-block;
    padding: 0 16px;
  }

  &__burger {
    cursor: pointer;
  }

  &__burger-row {
    margin-bottom: 12px;
  }

  &__lang-mobile {
    position: absolute;
    bottom: $sliding-menu-padding;
    width: calc(100% - #{$sliding-menu-padding} * 2);
  }

  &__resto-owners {
    position: absolute;
    bottom: calc(#{$sliding-menu-padding} + 50px);
    width: calc(100% - #{$sliding-menu-padding} * 2);
  }

  &__user-row {
    display: flex;
  }

  &__username {
    @include text-overflow-ellipsis; // stylelint-disable-line

    flex: 0 1 auto;
    margin-left: 16px;
    color: $text-black;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
  }

  &__view-selector {
    margin-right: 12px;
  }

  &__burger-container {
    position: relative;
  }

  &__burger-notifications {
    @include top-menu-notifications; // stylelint-disable-line
  }
}
