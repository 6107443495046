@import '../../theme/styles/variables/index';

.place-feedback {
  &__content {
    padding: 24px 16px 24px 24px;
  }

  &__text {
    margin-bottom: 24px;
    font-size: 18px;
  }

  &__form {
    width: unset;
  }
}

@media screen and (max-width: $mobile-view-width) {
  .place-feedback {
    &__content {
      padding: 12px 8px 0 12px;
    }
  }
}
