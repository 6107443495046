@import '../../../theme/styles/variables/index';

.finish-registration-modal {
  width: 290px;

  &__row {
    margin-bottom: 16px;
  }

  &__actions {
    margin-top: 16px;
  }
}
