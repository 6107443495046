@import '../../../theme/styles/variables/index';

.dish-details-modal {
  width: 600px;
  padding: 16px !important;

  &__photo {
    width: 100%;
    min-height: 340px;
    border-radius: 4px;
    object-fit: contain;
  }

  &__title-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
    margin-top: 16px;
    line-height: 32px;
  }

  &__description-row {
    display: flex;
    justify-content: space-between;
    gap: 16px;
    margin-top: 12px;
  }

  &__actions-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
    margin-top: 24px;
    margin-bottom: 4px;
  }

  &__name-section {
    display: flex;
    align-items: center;
    gap: 4px;
  }

  &__name {
    font-size: 24px;
    font-weight: 500;
  }

  &__price {
    font-size: 24px;
    font-weight: 600;
    color: $color-primary-strong;
  }

  &__description {
    font-size: 16px;
    line-height: 150%;
  }

  &__like-wrapper {
    flex: 0 0 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background: #fff;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.12);
    cursor: pointer;

    &--liked {
      background-color: $color-primary;
    }
  }

  &__like-icon {
    position: relative;
    top: 2px;
  }

  &__tags {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  &__weight {
    color: $text-secondary;
    font-size: 18px;
  }

  &__tag {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.12);
  }

  .svg-icon__heart {
    width: 28px;
  }
}
