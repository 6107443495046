@import '../../../theme/styles/variables/index';

.reservation-success-modal {
  width: 400px;
  text-align: center;

  &__action {
    display: flex;
    justify-content: center;
    margin-top: 16px;
  }

  p {
    margin-bottom: 16px;
    font-size: 15px;
    line-height: 20px;
  }

  li {
    margin-bottom: 8px;
    font-size: 15px;
    line-height: 20px;
    text-align: left;
  }
}
