@import '../../../theme/styles/variables/index';

.query-result {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  gap: 4px;

  &__results {
    font-size: 18px;
    font-weight: 700;
  }

  &__label {
    font-size: 14px;
  }
}
