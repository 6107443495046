@import '../../../theme/styles/variables/index';

$avatar-size: 80px;

.user-profile {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;

  &__form {
    flex-grow: 1;
  }

  .profile-page__form-container {
    flex-grow: 1;
    margin: 0 16px 24px 0;
  }

  .statistics {
    width: 350px;
  }

  .avatar {
    display: flex;
    justify-content: space-between;
    overflow: unset;

    &__img-wrapper {
      width: $avatar-size;
      height: $avatar-size;
      overflow: hidden;
      border-radius: 50%;
    }

    &__controls {
      width: calc(100% - #{$avatar-size} - 20px);
      font-size: 12px;
      line-height: $avatar-size / 3;
    }

    &__title {
      color: $text-black;
    }

    &__button-wrapper {
      width: max-content;
    }

    &__action-button {
      justify-content: flex-start;
      padding: 0;
      color: $text-default;
    }

    &__delete:hover,
    &__delete:focus {
      color: $color-danger;

      .fill {
        fill: $color-danger;
      }
    }

    &__new:hover,
    &__new:focus {
      color: $color-primary;

      .fill {
        fill: $color-primary;
      }
    }

    &__button-container {
      display: inline-block;
      margin-top: 20px;
    }
  }

  .info {
    margin: 10px 0 14px;
    color: $text-default;
    font-weight: 500;
    text-align: center;
  }

  .stats {
    display: flex;
    flex-wrap: wrap;
    color: $text-black;

    &__item {
      display: flex;
      align-items: center;
      width: 50%;
      line-height: 30px;
    }

    &__icon {
      margin-right: 10px;
    }
  }

  .achievements {
    &__item {
      display: flex;
      align-items: center;
      color: $text-default;
      font-size: 14px;
      line-height: 30px;
    }

    &__icon {
      margin-right: 9px;
    }
  }

  .divider {
    height: 1px;
    margin: 20px 0;
    background-color: $text-grey;
  }

  &__user-info {
    width: 516px;
  }

  .user-data {
    &__row {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      min-height: 65px;
      margin-top: 10px;

      &--center {
        display: flex;
        align-items: center;
      }
    }

    &__column {
      width: 47%;
    }
  }

  &__social {
    width: 350px;
  }

  .social {
    &__link {
      color: $text-default;
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
    }

    &__item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 10px 0;
      color: $text-default;
      font-weight: 500;
      font-size: 12px;
    }

    &__unlink {
      justify-content: center;
      min-width: 82px;
      padding: 0 10px;
    }
  }
}

@media screen and (max-width: $mobile-view-small-width) {
  .user-profile {
    .user-data {
      &__column {
        width: 100%;

        &:last-child {
          margin-top: 10px;
        }
      }
    }
  }
}
