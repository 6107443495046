@import '../../theme/styles/variables/index';

.payment {
  display: flex;
  justify-content: space-between;
  max-width: $max-container-width;
  margin: 0 auto;
  padding: 24px 40px;

  &__reservation-info {
    min-width: 0;
    max-width: 470px;
    margin: 0 auto;
  }

  &__amount {
    font-size: 24px;
    font-weight: 600;
    margin: 10px 0;
  }

  &__submit {
    margin-top: 10px;
  }

  .reservation-info {
    &__right {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }

    &__title {
      margin-bottom: 16px;
      color: $text-default;
      font-size: 16px;
    }

    &__details {
      display: flex;
      font-size: 14px;
    }

    &__photo {
      flex: 0 0 54px;
      height: 54px;
      margin-right: 12px;
      overflow: hidden;
      border-radius: 4px;
    }

    &__resto-name {
      color: $text-black;
      font-weight: 600;
      font-size: 20px;
    }

    &__timer {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      margin: 10px 0 12px;
      padding: 0 20px;
      color: $text-black;
      line-height: 36px;
      background-color: $color-menu-hover;
      border-radius: 4px;
      font-size: 14px;
    }

    .timer {
      &__icon {
        margin-right: 9px;
      }

      &__time {
        display: inline-block;
        font-weight: 500;
      }
    }

    &__busy-hours {
      padding: 5px 20px;
      font-size: 14px;
      line-height: 20px;
      background-color: rgba($color-yellow, 0.7);
      border-radius: 4px;
      white-space: pre-line;
    }
  }

  .payment-button {
    margin-top: 16px;
    height: 40px;
    border-radius: 8px;
    background-color: $color-primary;
    font-size: 16px;
    cursor: pointer;
  }

  .payment-success {
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    padding: 40px;
    background-color: $color-primary-extra-light;
    border-radius: 8px;

    &__message {
      font-size: 18px;
      color: $text-default;
    }

    &__amount {
      font-size: 24px;
      font-weight: 600;
    }

    &__divider {
      align-self: normal;
      margin-left: -40px;
      margin-right: -40px;
      height: 1px;
      background-color: $text-grey;
    }

    &__data-row {
      width: 100%;
      display: flex;
      justify-content: space-between;
      gap: 16px;
    }

    &__label {
      font-size: 14px;
      color: $text-secondary;
    }

    &__value {
      font-size: 18px;
      font-weight: 500;
    }

    &__back {
      display: block;
      width: 100%;
      margin-top: 16px;

      .button {
        width: 100%;
      }
    }
  }

  .payment-form__loading {
    position: absolute;
    left: 0;
    width: 100%;
    height: 710px;
    padding: 48px;
    text-align: center;
    font-size: 20px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    background-color: white;
    z-index: 1;
  }
}

.link-expired {
  padding: 100px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  font-size: 18px;
  color: $text-default;

  .button {
    font-size: 16px;
  }
}

@media screen and (max-width: 560px) {
  .payment {
    padding: 12px 20px;
  }
}
