@import '../../theme/styles/variables/index';

.view-selector {
  &--small {
    button {
      min-width: unset;

      .svg-icon {
        margin-right: 0;
      }
    }
  }

  button {
    &:hover,
    &:focus {
      //background-color: $color-menu-hover;
    }
  }
}
