@import '../../theme/styles/variables/index';

.error-page {
  position: absolute;
  top: 50%;
  left: 50%;
  max-width: 512px;
  color: $text-secondary;
  text-align: center;
  transform: translate(-50%, -50%);

  &__status {
    margin-bottom: 16px;
    color: $text-black;
    font-weight: 500;
    font-size: 96px;
  }

  &__message {
    margin-bottom: 16px;
    font-size: 24px;
  }

  &__details {
    font-size: 16px;
    line-height: 24px;
  }

  &__link {
    margin-top: 48px;
  }
}
