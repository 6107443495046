@import '../../theme/styles/variables/index';

.top-menu {
  position: relative;

  &__options {
    position: absolute;
    right: 0;
    z-index: 10;
    width: max-content;
    background-color: #fff;
    border: 1px solid $text-grey;
    border-radius: 4px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  }

  &__option {
    position: relative;
    width: 100%;
    padding: 0 8px 0 32px;
    color: $text-default;
    line-height: 36px;
    border-bottom: 1px solid $text-grey;
    outline: none;
    cursor: pointer;

    &:hover {
      background-color: $color-menu-hover;
    }

    &:first-child {
      border-top: 1px solid $text-grey;
    }

    &--selected {
      background-color: $color-menu-hover;
    }

    &--has-notifications {
      display: flex;
      justify-content: space-between;
      align-items: center;
      min-width: 190px;
    }

    &--has-submenu {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  &__submenu-option {
    padding-left: 32px;
    color: $text-default;
    line-height: 48px;
    cursor: pointer;

    &:hover {
      background-color: $color-menu-hover;
    }

    &--selected {
      background-color: $color-menu-hover;
    }
  }

  &__icon {
    position: absolute;
    top: 10px;
    left: 8px;
  }

  &__notifications {
    width: 38px;
    height: 16px;
    margin-left: 4px;
    color: #fff;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    background-color: $color-primary-strong;
    border-radius: 8px;
  }

  &__chevron {
    width: 16px;
  }

  &--inline {
    .top-menu__options {
      position: unset;
      width: calc(100% + #{$sliding-menu-padding} * 2);
      margin-left: -$sliding-menu-padding;
      font-size: 16px;
      border: none;
      box-shadow: none;
    }

    .top-menu__option {
      line-height: 48px;
    }

    .top-menu__icon {
      top: 13px;
      left: 6px;
      width: 20px;
    }

    .top-menu__notifications {
      font-size: 15px;
      line-height: 16px;
    }
  }
}
