@import '../../../theme/styles/variables/index';

.toggle-buttons {
  display: flex;
  align-items: center;
  color: $text-default;
  font-size: 12px;
  line-height: 30px;
  border-radius: 4px;
  outline: none;

  &__item {
    display: flex;
    flex: 1 1 auto;
    justify-content: center;
    align-items: center;
    padding: 0 15px;
    border: 0 solid $text-grey;
    cursor: pointer;

    &:hover,
    &:focus {
      color: #fff;
      background-color: $color-primary;
    }

    &:active {
      color: #fff;
      background-color: $color-primary-strong;
    }

    &--selected {
      color: #fff;
      background-color: $color-primary;
    }

    &:first-child {
      border-width: 1px;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }

    &:last-child {
      border-width: 1px 1px 1px 0;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }

    &:not(:first-child):not(:last-child) {
      border-width: 1px 1px 1px 0;
    }
  }
}
