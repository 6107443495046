@import '../../../theme/styles/variables/index';

.ask-phone-modal {
  width: 310px;

  &__text {
    margin-bottom: 20px;
    color: $text-default;
    font-size: 14px;
    line-height: 18px;
  }

  &__row {
    margin-bottom: 16px;
  }

  &__actions {
    margin-top: 16px;
  }
}
