@import '../../../theme/styles/variables/index';

.slider {
  position: relative;
  max-width: $max-container-width;
  margin: 0 auto;

  &__image {
    min-height: 200px;
  }

  .slick {
    &-dots {
      bottom: 5px !important;

      li button::before {
        color: $text-disabled !important;
        font-size: 12px !important;
        opacity: 0.7 !important;
      }

      li.slick-active button::before {
        color: #fff !important;
        opacity: 1 !important;
      }
    }

    &-next {
      right: 25px !important;
    }

    &-prev {
      left: 25px !important;
      z-index: 1;
    }
  }
}
