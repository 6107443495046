@import '../../../theme/styles/variables/index';

.calendar__wrapper {
  position: relative;
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 16px;

  .calendar {
    width: 140px;
    height: 40px;
    padding: 0 10px;
    color: $text-default;
    font-size: 18px;
    line-height: 30px;
    border: none;
    background-color: $text-light-grey;
    border-radius: 8px;
    outline: none;
    cursor: pointer;

    &__icon {
      width: 24px;
    }

    &__chevron {
      position: absolute !important;
      top: 17px;
      right: 10px;
      pointer-events: none;
    }

    &:hover {
      background-color: $color-menu-hover;
    }
  }
}

#calendar-wrapper {
  .react-datepicker {
    font-size: 18px !important;
    font-family: 'Onest', sans-serif;

    &-wrapper {
      width: 100%;
    }

    &__triangle {
      display: none;
    }

    &-popper {
      z-index: 5000 !important;
    }

    &__header {
      background-color: #fff !important;
    }

    &__current-month {
      color: $text-default !important;
    }

    &__day-name {
      color: $text-black !important;
    }

    &__day {
      width: 1.55rem;
      margin: 0.15rem !important;
      color: $text-black !important;
      border: 1px solid transparent !important;

      &--today {
        color: #fff !important;
        background-color: $color-primary !important;
        border-radius: 4px !important;
      }

      &--selected {
        color: $color-primary-strong !important;
        background-color: #fff !important;
        border: 1px solid $color-primary !important;
      }

      &--disabled {
        color: $text-disabled !important;
      }

      &:hover:not(&--disabled):not(&--selected):not(&--today) {
        background-color: #fff !important;
        border: 1px solid $color-primary !important;
      }

      &--disabled:hover {
        border-color: transparent !important;
      }
    }
  }
}
