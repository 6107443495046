@import '../../../theme/styles/variables/index';

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  line-height: 30px;
  border: none;
  border-radius: 4px;
  outline: none;
  cursor: pointer;
  -webkit-transition: background-color $transition, color $transition, border $transition;
  transition: background-color $transition, color $transition, border $transition;

  .fill {
    -webkit-transition: fill $transition;
    transition: fill $transition;
  }

  &--default {
    color: #fff;
    background-color: $color-primary-very-dark;

    &:hover,
    &:focus {
      background-color: $color-primary;

      .fill {
        fill: $color-primary;
      }
    }

    &:active {
      background-color: $color-primary-middle;

      .fill {
        fill: $color-primary-middle;
      }
    }

    &[disabled] {
      background-color: $text-grey;
      border: 1px solid $text-disabled;
    }
  }

  &--flat {
    color: $color-primary-very-dark;
    background-color: #fff;
    border: 1px solid $color-primary-very-dark;

    &:hover,
    &:focus {
      color: $color-primary;
      border-color: $color-primary;

      .fill {
        fill: $color-primary;
      }
    }

    &:active {
      color: $color-primary-middle;
      border-color: $color-primary-middle;

      .fill {
        fill: $color-primary-middle;
      }
    }
  }

  &--danger {
    color: $color-danger;
    background-color: #fff;
    border: 1px solid $text-disabled;

    &:hover {
      color: #fff;
      background-color: $color-danger;
      border-color: $color-danger;

      .fill {
        fill: $color-danger;
      }
    }

    &:focus {
      color: $color-danger;
      border: 1px solid $color-danger;
      border-color: $color-danger;

      .fill {
        fill: $color-danger;
      }
    }

    &:active {
      color: #fff;
      background-color: $color-danger-pressed;
      border-color: $color-danger-pressed;

      .fill {
        fill: $color-danger-pressed;
      }
    }
  }

  &[disabled] {
    color: $text-disabled;
    background-color: $text-light-grey;
    border: 1px solid $text-disabled;
    cursor: not-allowed;
  }

  &--large {
    min-width: 104px;
    height: 32px;
    padding: 0 10px;
  }

  &--medium {
    min-width: 82px;
    height: 32px;
    padding: 0 10px;
    font-weight: 500;
  }

  &--small {
    min-width: 59px;
    height: 24px;
    padding: 0 8px;
    line-height: 22px;
  }

  &__icon {
    margin-right: 10px;
  }

  &--full-width {
    width: 100%;
  }
}
