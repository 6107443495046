@import '../../theme/styles/variables/index';

.policy {
  display: flex;
  background-color: $text-light-grey;

  &__tabs {
    min-width: 250px;
  }

  &__content {
    width: 100%;
    margin: 20px;
    padding: 20px;
    overflow: auto;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: $box-shadow-light;
  }

  &__heading {
    margin-bottom: 24px;
    color: $text-default;
    font-weight: 500;
    font-size: 24px;
    text-align: center;
  }

  &__text {
    color: $text-default;
    font-size: 14px;
    line-height: 22px;

    & strong {
      font-weight: bold;
    }

    & div > * {
      padding: 0.7rem;
    }

    ol {
      padding: 0 1.2rem;
      list-style-type: decimal;
    }

    ul {
      list-style-type: unset;

      li {
        margin-left: 20px;
      }
    }

    h2 {
      font-weight: bold;
      font-size: 20px;
    }

    h3 {
      font-weight: bold;
      font-size: 16px;
    }
  }
}

@media screen and (max-width: $mobile-view-width) {
  .policy {
    &__content {
      margin: 0;
      padding: 10px;
    }
  }
}
