@import '../../theme/styles/variables/index';

.main-page-search-input {
  position: relative;
  width: 100%;

  &__input-wrapper {
    .svg-icon {
      top: 24px;
      width: 20px;
    }

    .svg-icon__search {
      left: 14px;
    }

    .svg-icon__close {
      right: 80px;
    }
  }

  &__input {
    height: 70px;
    padding-left: 44px;
    padding-right: 108px;
    border-radius: 16px;
  }

  &__ai-search {
    position: absolute;
    top: 3px;
    right: 3px;
    min-width: unset !important;
    width: 64px;
    height: 64px;
    border-radius: 12px;

    .svg-icon {
      width: 24px;
    }
  }

  &__ai-hint {
    position: absolute;
    top: -24px;
    right: -202px;
    z-index: 1;
    pointer-events: none;
  }

  &__hint-label {
    position: absolute;
    top: 35px;
    right: 7px;
    color: white;
    font-size: 10px;
    font-weight: 700;
    white-space: nowrap;
  }
}

@media screen and (max-width: $mobile-view-width) {
  .main-page-search-input {
    &__input-wrapper {
      .svg-icon {
        top: 18px;
      }
    }

    &__input {
      height: 56px;
    }

    &__ai-search {
      height: 50px;
    }
  }
}
