@import '../../../theme/styles/variables/index';

.side-tabs {
  padding-top: 12px;
  color: $text-default;
  background-color: #fff;
  cursor: pointer;

  &__tab {
    display: block;
    margin: 8px 0;
    padding-left: 48px;
    font-size: 14px;
    line-height: 40px;
    text-decoration: none !important;
    user-select: none;

    &:hover {
      color: $color-primary-strong;
    }

    &:focus,
    &:active {
      background-color: $color-menu-hover;
    }

    &--selected {
      color: $color-primary-strong;
      background-color: $color-menu-hover;
      box-shadow: inset -3px 0 0 0 $color-primary-strong;

      &:visited {
        color: $color-primary-strong;
      }
    }

    &[disabled] {
      color: $text-disabled;
      cursor: not-allowed;
    }
  }
}
