@import '../../../theme/styles/variables/index';

.popup {
  position: fixed;
  z-index: 10000;
  padding: 16px;
  background-color: white;
  box-shadow: $box-shadow-light;

  &__wrapper {
    position: fixed;
    z-index: 10000;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    background: transparent;
  }
}
