@import '../../../theme/styles/variables/index';

.textarea {
  padding: 10px;
  color: $text-default;
  font-size: 16px;
  background-color: #fff;
  border: 1px solid $text-disabled;
  border-radius: 4px;
  outline: none;
  resize: none;

  &::placeholder {
    color: $text-disabled;
  }

  &--full-width {
    width: 100%;
  }

  &--disabled {
    opacity: 0.25;
  }
}
