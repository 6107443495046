@import '../../../theme/styles/variables/index';

.text-input {
  padding: 0 10px;
  color: $text-default;
  font-size: 16px;
  line-height: 40px;
  border: 1px solid $text-grey;
  border-radius: 4px;
  outline: none;

  &__wrapper {
    position: relative;
    display: inline-block;
    font-size: 12px;

    .text-input__start-icon {
      position: absolute;
      top: 8px;
      left: 8px;
    }

    .text-input__end-icon {
      position: absolute;
      top: 8px;
      right: 8px;
    }

    .clickable {
      cursor: pointer;
    }
  }

  &::placeholder {
    color: $text-disabled;
  }

  &__label {
    color: $text-black;
    line-height: 20px;
  }

  &--has-icon {
    padding-left: 32px;
  }

  &--has-label .svg-icon {
    top: 28px;
  }

  &--full-width {
    width: 100%;
  }

  &--has-error {
    border-color: $color-danger;
  }

  &__error {
    position: absolute;
    bottom: -15px;
    color: $color-danger;
    font-size: 12px;
  }
}
