@import '../../../theme/styles/variables/index';

.map {
  width: 100%;
  height: 240px;

  &--full-height {
    height: 100%;
  }

  .custom-marker {
    position: relative;

    &--user {
      &::before {
        position: absolute;
        top: -3px;
        left: -4px;
        display: block;
        width: 24px;
        height: 24px;
        background-color: $color-link-main;
        border-radius: 50%;
        content: '';
      }

      &::after {
        position: absolute;
        top: -23px;
        left: -24px;
        display: block;
        width: 64px;
        height: 64px;
        background-color: $color-link-main;
        border-radius: 50%;
        opacity: 0.15;
        content: '';
      }
    }

    &__avatar {
      width: 50px;
      height: 50px;
      border: 1px solid $color-primary-dark;
      border-radius: 50%;
      transform: translate(-25px, -25px);
      transition: all 1s;
    }

    &__small {
      width: 28px;
      transform: translate(-9px, -29px);
    }
  }
}

.leaflet-container {
  z-index: 5;
  height: 100%;
}

.leaflet-marker {
  &--priority {
    z-index: 99999999 !important;
  }

  &:hover {
    z-index: 100000000 !important;
  }
}

.leaflet-tooltip--hovered {
  z-index: 100000000 !important;
}

.place-tooltip {
  &.leaflet-tooltip-left {
    top: -6px;
    left: -29px;
  }

  &.leaflet-tooltip-right {
    top: -6px;
    left: 14px;
  }

  &--small {
    &.leaflet-tooltip-left {
      top: -20px;
      left: -14px;
    }

    &.leaflet-tooltip-right {
      top: -20px;
      left: 14px;
    }
  }
}
